import classNames from "classnames/bind";
import PropTypes from "prop-types";
import React from "react";
import { IN_HOUSE_URL } from "../../../_consts";
import { createEventId } from "../../../_helpers";
import { sendLog } from "../../../v2/businesslogics/logging";
import styles from "./index.module.scss";

const cx = classNames.bind(styles);

function NudgeBannerMyPage({ subTitle, style }) {
  const _web = async () => {
    const eventID = createEventId();
    await sendLog("mypage_to_web", {}, eventID);
    window.location = IN_HOUSE_URL.상품탐색;
  };

  return (
    <div style={style}>
      <div className={cx("row")} style={{ marginTop: "0" }}>
        <div
          className={cx("cta")}
          onClick={() => {
            _web();
          }}
        >
          스파르타 추천 강의 보러가기
        </div>
      </div>
    </div>
  );
}

NudgeBannerMyPage.propTypes = {
  subTitle: PropTypes.string,
  style: PropTypes.object,
};

export { NudgeBannerMyPage };
