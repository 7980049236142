import classNames from "classnames/bind";
import React from "react";
import { connect } from "react-redux";
import { IN_HOUSE_URL } from "../../../../../../_consts";
import { history } from "../../../../../../_helpers";
import { useDevice } from "../../../../../pages/NBCampAttendPage";
import styles from "./ClassCardNone.module.scss";

const cx = classNames.bind(styles);

export default function ClassCardNone({ current_type }) {
  const device = useDevice();
  const isDesktop = device === "pc";

  const handleBtnClick = () => {
    {
      current_type === "purchased" &&
        window.location.assign(IN_HOUSE_URL.상품탐색);
    }
    {
      current_type === "done" && history.push(IN_HOUSE_URL.신_내강의실);
    }
    {
      current_type === "voucher" &&
        window.location.assign(IN_HOUSE_URL.상품탐색);
    }
  };
  return (
    <>
      <div>
        <div className={cx("no_course")}>
          <div className={cx("no_course_msg")}>
            {current_type === "purchased" && (
              <>
                <div className={cx("no_course_top_text")}>
                  아직 수강하는 강의가 없어요.
                </div>
                <div className={cx("no_course_bot_text")}>
                  <div>어떤 강의를 들으면 좋을지 보러 가요!</div>
                  <img src={`/assets/icons/running.png`} className="" />
                </div>
              </>
            )}
            {current_type === "done" && (
              <>
                <div className={cx("no_course_top_text")}>
                  아직 완주한 강의가 없어요.
                </div>
                <div className={cx("no_course_bot_text")}>
                  <div>얼른 완주하고 회고 쓰러 가요!</div>
                  <img src={`/assets/icons/winking.png`} className="" />
                </div>
              </>
            )}

            {current_type === "voucher" && (
              <>
                <div className={cx("no_course_top_text")}>
                  아직 수강권이 없어요.
                </div>
                <div className={cx("no_course_bot_text")}>
                  <span>
                    코딩이 궁금하다면{!isDesktop && <br />} 스파르타 추천 강의와
                    함께 시작해보세요!
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={cx("no_course_btn")} onClick={() => handleBtnClick()}>
            <div className="no_course_btn_content_wrapper">
              {current_type === "done" && "내 강의실 가기"}
              {current_type === "purchased" && "강의 둘러보기"}
              {current_type === "voucher" && "스파르타 추천 강의 보러가기"}
            </div>
            <img src={`/assets/icons/picker_b.png`} alt="" />
          </div>
        </div>
      </div>
      {/*{current_type === "voucher" && (*/}
      {/*  <div className={cx("package_guide_wrapper")}>*/}
      {/*    <span className={cx("package_guide_msg")}>*/}
      {/*      잠깐! 스파르타 패키지가 무엇인지 궁금하다면?*/}
      {/*    </span>*/}
      {/*    <ClassPackageGuide />*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const connectedClassCardNone = connect(mapStateToProps, {})(ClassCardNone);
export { connectedClassCardNone as ClassCardNone };
