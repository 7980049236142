import { useState } from "react";
import { history } from "../../../../../_helpers";
import axios from "../../../../../_helpers/axios";
import { useGetCouponInfo } from "../../../../queries/mypage";
import { Coupon } from "../../../molecules/MyPage/Coupon/Coupon";
import * as S from "./MyCoupon.style";

export const MyCoupon = () => {
  const [couponCode, setCouponCode] = useState("");
  const { data: couponInfo } = useGetCouponInfo();

  const handleCodeChange = (e) => {
    setCouponCode(e.target.value.trim());
  };

  const handleRegisterBtn = async () => {
    if (!couponCode) {
      alert("쿠폰 코드를 입력해주세요.");
      return;
    }

    try {
      const { data } = await axios.post("/coupons/register", { couponCode });
      alert(data.msg);
      if (data.ok) {
        /** b2b 소규모 상품권 지급 대상 쿠폰 코드 로직 **/
        if (couponCode.includes("enterprise")) {
          history.push("/mypage/myvoucher");
          window.location.reload();
          return;
        }
        window.location.reload();
      }
    } catch (error) {
      alert(error.response.data.message);
      setCouponCode("");
    }
  };

  return (
    <S.MyCouponWrapper>
      <S.Title>쿠폰 등록하기</S.Title>
      <S.InputWrapper>
        <S.InputContainer>
          <S.CouponCodeInput
            type="text"
            id="name1"
            placeholder={"등록할 쿠폰 코드를 입력해 주세요."}
            onChange={handleCodeChange}
            value={couponCode}
          />
          <S.CouponRegisterButton
            onClick={handleRegisterBtn}
            type="button"
            value={"등록"}
          />
        </S.InputContainer>
      </S.InputWrapper>

      {couponInfo && (
        <S.CouponSectionWrapper>
          <S.SubTitle>
            내 쿠폰 <b>{couponInfo?.length}</b>장
          </S.SubTitle>
          <S.CouponsWrapper>
            {couponInfo?.map((coupon, idx) => (
              <Coupon key={"coupon" + idx} couponData={coupon} />
            ))}
          </S.CouponsWrapper>
          {couponInfo?.length === 0 && (
            <S.NoCouponGuide>
              <S.NoCouponGuideImg
                src={`/assets/icons/rtan/rtan_with_flower.png`}
              />
              <S.NoCouponGuideTitle>등록된 쿠폰이 없어요.</S.NoCouponGuideTitle>
              <S.NoCouponGuideDesc>
                당신에게만 드리는 시크릿 쿠폰!
                <br />
                쿠폰 코드 <b>lovesparta</b>를 입력해 주세요.
              </S.NoCouponGuideDesc>
            </S.NoCouponGuide>
          )}
        </S.CouponSectionWrapper>
      )}
    </S.MyCouponWrapper>
  );
};
