import React, { Component } from "react";
import { NavContainer } from "../../_containers";
import { createEventId, history, params } from "../../_helpers";
import { config } from "../../config";
import { Skeleton } from "antd";
import classNames from "classnames/bind";
import styles from "./PaymentPrequestionPage.module.scss";
import { connect } from "react-redux";
import { getGETAuthOptions } from "../../_urls";
import { courseActions, userActions } from "../../_actions";
import { RcmdModal } from "../../_components/RcmdModal";
import { prequestionActions } from "../../_actions/prequestion.actions";
import {
  enrolledActions as enrolledsAction,
  enrolledActions,
  ordersActions,
  prequestionAction,
  roundsActions,
} from "../../v2/_state/actions";
import { NBCAMP_COURSE_IDS, sendLog } from "../../v2/businesslogics/logging";
import "./PaymentPrequestionPage.antdesign.custom.css";
import { getCookie, getParamByKey } from "../../v2/_helper";
import { getRef } from "../../v2/_helper/utils";
import privateInstance from "../../v2/_helper/axios";
import { blockingCourseIds } from "../../v2/utils/blockingCourseIds";
import { FooterV2 } from "../../_components/Footer/FooterV2";
import { devCareerPrepCoursesList } from "../../v2/pages/FreeFinishPage/variables";

const cx = classNames.bind(styles);

class PaymentPrequestionPage extends Component {
  state = {
    isLoading: true,
    refDetailRequired: false,
    wantRequired: false,
    course_id: this.props.match.params.course_id,
    round_id: this.props.match.params.round_id,
    isFree: false,
    isRealName: true,
    hasWeekZero: false,
    isGov: false,
    isGovIntro: false,
    isB2b: false,
    isPackage: false,
    isForever: false,
    zeroweekModalVisible: false,
    rcmdVisible: false,
    // nbVisible: this.isGovIntro(),
    refDetailPlaceholder:
      "스파르타를 아시게 된 경로가 궁금해요! (예. 뉴스기사, 로켓펀치 등)",
    ...(this.isGovIntro() ? { courseSelected: 0 } : {}),
    answerMap: {},
  };

  validateUser(isLogined, user) {
    // 로그인 안했을 시 실행흐름 차단 & 리디렉션
    if (!isLogined && !user) {
      // alert("로그인이 필요합니다.\n로그인 페이지로 이동합니다.");
      // history.push(`/login?next=${getNextUrl()}`);
      // return;
    }

    const token = getCookie("token");
    if (isLogined && token) {
      this.props.loginWithToken(token);
    }

    // 로그인 함
  }

  initializePage() {
    window.scrollTo(0, 0);
  }

  logCompleteRegistration() {
    if (params().logined && params().is_new) {
      const eventID = createEventId();
      sendLog("complete_registration", {}, eventID);
    }
  }

  initRelatedReducers() {
    const { course_id, round_id } = this.state;
    this.props.getGovCourse();
    this.props.setRound(round_id);
    this.props.getOrder(course_id); // TODO: ad_sent 가 true 로 바뀌는 API
    this.props.getCourse(course_id);
    this.props.getPrequestionExist(course_id, round_id);
    this.props.getActiveEnrolleds();
    this.props.isPreqSubmitted(course_id, round_id);
  }

  initCourse(course_id) {
    if (this.props.user) {
      this.props.getCourse(course_id);
    }
  }

  sendPageView() {
    if (this.props.orders.loaded && this.props.course.loaded) {
      const mockOrder = this.createMockOrder();
      let order = this.props.orders.data.context;
      if (Object.keys(order).length === 0) {
        order = mockOrder;
      }
      const logHeader =
        this.props.course.data.is_gov_credit ||
        this.props.course.data.is_gov_intro
          ? "nb"
          : "scc";
      sendLog(`${logHeader}_prequestionPage_view`, order);
      this.getPaymentInfo();
    }
  }

  initStates() {
    if (this.props.course.loaded) {
      this.setState({
        period: this.props.course.data.period,
        isSeasonal: this.props.course.data.is_seasonal,
        isTrial: this.props.course.data.is_trial,
        isPackage: this.props.course.data.is_temp_package,
        isForever: this.props.course.data.is_forever,
        hasWeekZero: this.props.course.data.has_weekzero,
        isGov: this.props.course.data.is_gov,
        isGovCredit: this.props.course.data.is_gov_credit,
        isPirates: this.props.course.data.is_pirates,
        title: this.props.course.data.title,
        // nbVisible: this.isGovIntro(),
      });
    }
  }

  checkRoundInfo() {
    if (this.props.activeEnrolleds.loaded && this.props.course.loaded) {
      for (let enrolled of this.props.activeEnrolleds.data) {
        const { round_id } = this.props.match.params;
        if (round_id === enrolled.round_id) {
          if (this.props.course.data.course_id === enrolled.course_id) {
            this.setState({
              enrolledId: enrolled.enrolled_id,
              roundId: enrolled.round_id,
              courseId: enrolled.course_id,
              isAI: enrolled.course_id === "64939ff350f4c9159ab204d8",
              isNocodeFree: enrolled.course_id === "642c2dfff6dfefee6dc47bfb",
            });
          }
        }
      }
    }
  }

  checkEnrolledDetail() {
    if (
      this.state.enrolledId &&
      !this.props.enrolledDetails.loading &&
      !this.props.enrolledDetails.loaded
    ) {
      this.props.getEnrolledDetails(this.state.enrolledId);
    }
  }

  checkExistPrequestion() {
    if (this.props.isPrequestionExist.loaded) {
      if (!this.props.isPrequestionExist.data) {
        return;
      }
    }
    if (this.props.prequestionSubmitted.data) {
      alert("이미 사전질문을 제출하셨습니다!");
      history.push("/classroom");
    }
  }

  checkIsExtend() {
    if (this.props.course.loaded) {
      if (this.props.course.data.keyword === "extend") {
        history.push("/classroom");
      }
    }
  }

  isChatGptCourse() {
    return blockingCourseIds.includes(this.state.course_id);
  }

  /**
   * 첫 실행 시 작동하는 함수
   * 1. 로그인 중인지 확인
   * 2. 페이지 초기화
   * 3. 필요한 전역 상태 초기화
   * 4. 로깅*/
  componentDidMount() {
    const { logined: isLogined } = params();
    const { user } = this.props;
    this.validateUser(isLogined, user);
    this.initializePage();
    this.initRelatedReducers();
    this.logCompleteRegistration();

    if (this.state.course_id === "6001384a006d2727411520ea") {
      this.setState({
        isFree: true,
        rcmdVisible: false,
      });
    }

    // 자유수강권 mypage/myvoucher로 리다이렉트
    if (this.state.course_id === "61af189856027c9c30581b68") {
      history.push("/mypage/myvoucher");
    }

    if (getRef() === "comento") {
      this.setState({ reference: "코멘토" });
    }
  }

  /**
   * 사전설문 작성에 필요한 정보가 없을경우 채워주는 함수
   * 1. 코스 정보 및 state 초기화
   * 2. pageView 로깅
   * 3. round 정보가 부족할 경우 요청
   * 4. enrolled_detail 이 없을경우 요청 (classroom 에서 올때는 있으나 payment 에서 올때는 없음)
   * 5. 이미 사전설문을 제출 했었는지 확인*/

  isUpdatedNow(prevProps, propsName) {
    return !prevProps[propsName].loaded && this.props[propsName].loaded;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { course_id } = this.state;
    if (this.isUpdatedNow(prevProps, "user")) this.initCourse(course_id);
    if (this.isUpdatedNow(prevProps, "course")) this.initStates();
    if (
      this.isUpdatedNow(prevProps, "course") ||
      this.isUpdatedNow(prevProps, "orders")
    )
      this.sendPageView();
    if (
      this.isUpdatedNow(prevProps, "course") ||
      this.isUpdatedNow(prevProps, "activeEnrolleds")
    )
      this.checkRoundInfo();
    if (this.isUpdatedNow(prevProps, "isPrequestionExist"))
      this.checkExistPrequestion();
    if (this.isUpdatedNow(prevProps, "round")) {
      if (this.props.round.loaded) {
        const { round: round_title, isB2b } = this.props.round.data;
        this.setState({
          isUnivIntensive: round_title.includes("대학생불꽃반"),
          isB2b: isB2b,
        });
      }
    }
    this.checkEnrolledDetail();
    this.checkIsExtend();
  }

  createMockOrder = () => {
    const course_data = this.props.course.data;
    return {
      course: {
        _id: course_data?.course_id,
        title: course_data?.title,
        is_developer_trial: course_data?.is_developer_trial,
        created_at: course_data?.created_at,
      },
      round: {
        round: course_data?.round,
        start_date: course_data?.enroll_start_date,
        end_date: course_data?.enroll_end_date,
      },
      orders: {
        status: "paid",
      },
      others: { gov_initial_status: "unknown", tutor: course_data.tutor },
    };
  };

  async sendPurchaseLogByCourseData(eventID) {
    const course_data = this.props.course.data;
    const mockOrder = this.createMockOrder();
    await sendLog("scc_purchase_completed_temp", {});

    if (course_data?.is_trial) {
      return this.logStartTrial(eventID, mockOrder);
    }

    if (course_data?.is_gov_trial) {
      return this.logStartGovTrial(eventID, mockOrder);
    }

    this.logPurchase(eventID, mockOrder);
  }

  getOrderPriceInfo = () => {
    const { data } = this.props.orders;
    if (!data.ok) {
      const course_data = this.props.course.data;
      return { price: course_data?.price, currency: "KRW" };
    }

    const order = data.context;
    let {
      order: { price, payment_method },
    } = order;

    let currency = "KRW";
    if (payment_method === "paypal") {
      currency = "USD";
      price = price / 100;
    }

    return { price, currency };
  };

  getPaymentInfo = async () => {
    const eventID = createEventId();
    const { data } = this.props.orders;
    const noOrder = !data.ok;
    const isSkip = getParamByKey("skip");

    if (noOrder) {
      return await this.sendPurchaseLogByCourseData(eventID);
    }

    const order = data.context;
    let {
      course: { title, free, is_seasonal, is_trial, is_gov_trial },
      order: { ad_sent },
      others: { is_not_free },
    } = order;

    if (ad_sent) {
      // 이미 로깅을 했습니다!
      return;
    }

    let category = this.state.isGov ? "내일배움단" : "스파르타 코딩클럽";

    await this.logPurchaseCompleted(order, category);

    if (is_gov_trial) {
      return this.logStartGovTrial(eventID, order);
    }
    const { course_id, round_id } = this.props.match.params;
    const { isGovCredit } = this.state;

    if (isGovCredit) {
      const searchParams = new URLSearchParams(window.location.search);
      const setCourseId = searchParams.get("set");
      window.location.href = `${
        process.env.REACT_APP_SCC_URL
      }/kdc/registration_status?course_ids=${course_id}${
        setCourseId ? "&course_ids=" + setCourseId : ""
      }&from=payment`;
    }

    if (is_trial) {
      try {
        this.logStartTrial(eventID, order);
      } catch (e) {
        console.log(e);
      }
      if (isSkip === "1") {
        const endpoint = `${config.apiUrl}/v1/payment/prequestion/${course_id}/${round_id}`;
        const data = { is_skipped: true };
        await privateInstance
          .post(endpoint, data) // 사전설문 가제출
          .then((resp) => {
            return resp.data;
          })
          .then(
            (result) => {
              if (result.ok) {
                const { enrolled_id, enrolled_detail_id } = result.context;
                history.push(
                  `/enrolleds/${enrolled_id}/edetails/${enrolled_detail_id}?course_id=${course_id}`
                );
              } else {
                alert("오류가 발생했습니다. 처음부터 다시 시도해주세요.");
              }
            },
            (error) => {
              alert(error);
            }
          );
      }
      return;
    }

    if (free || is_seasonal) {
      return this.logStartFree(eventID, order);
    }

    if (this.getOrderPriceInfo().price > 100 && is_not_free) {
      // 100원 테스트결제 제외
      this.logPurchase(eventID, order);
    }

    this.setState({
      title,
    });
  };

  logPurchase(eventID, order) {
    let {
      course: { _id: course_id, title },
    } = order;

    const { price, currency } = this.getOrderPriceInfo();
  }

  logStartFree(eventID, order) {
    let {
      course: { _id: course_id, title },
    } = order;
  }

  logStartTrial(eventID, order) {
    let {
      course: { _id: course_id, title },
    } = order;
  }

  logStartGovTrial(eventID, order) {
    let {
      course: { _id: course_id, title },
      others: { gov_initial_status },
    } = order;
  }

  async logPurchaseCompleted(order, category) {
    const is_gov_trial = this.props.course.data.is_gov_trial;
    const { isGovCredit } = this.state;
    const {
      course: {
        _id: course_id,
        title: course_title,
        display_price: show_price,
        is_trial: is_free,
      },
      order: {
        _id: order_no,
        user_id,
        email,
        phone,
        display_price,
        point,
        payment_method,
      },
      coupon_recipe: { title: coupon_title, benefit_detail: coupon_price },
      others: { gov_initial_status, country_code },
    } = order;
    const { price: final_price, currency } = this.getOrderPriceInfo();

    const base = {
      course_title,
      course_id,
      order_no,
      // for gtm
      onlineUserId: user_id,
      email: email,
      phone: phone.startsWith("0")
        ? country_code + phone.substring(1)
        : country_code + phone,
      content_type: "",
      content_name: course_title,
    };

    if (is_gov_trial) {
      const action = `nb_purchase_completed`;
      const data = Object.assign(base, {
        initial_status: gov_initial_status,
      });
      await sendLog(action, data, "", false, {}, true);
    } else if (isGovCredit) {
      const action = `kdc_purchase_completed`;
      const data = Object.assign(base, {
        course_title: course_title,
      });
      await sendLog(action, data, "", false, {}, true);
    } else {
      const action = `scc_purchase_completed`;
      const data = Object.assign(base, {
        show_price,
        display_price,
        is_free: is_free ?? false,
        coupon_title,
        coupon_price,
        point: point ?? 0,
        final_price,
        payment_method,
        // for gtm //
        value: final_price,
        currency: currency,
        is_nbcamp: NBCAMP_COURSE_IDS.includes(course_id),
      });
      await sendLog(action, data, "", false, {}, true);
    }

    if (!is_gov_trial) {
      let user = this.props.user;
      this.chtalkPurchaseLog(user, {
        content_name: course_title,
        content_type: "product",
        quantity: 1,
      });
    }
  }

  isAnswered(name) {
    return this.state.answerMap[name]?.isAnswered;
  }

  isValid(name) {
    return !(!this.state.isLoading && !this.isAnswered(name));
  }

  useAnswer(name) {
    const setState = (newState) => {
      this.setState({
        answerMap: {
          ...this.state.answerMap,
          [name]: newState,
        },
      });
    };
    return [this.state.answerMap[name], setState.bind(this)];
  }

  isGovIntro() {
    return this.props.course.data.title === "내일배움단 합류하기";
  }

  isTrial() {
    return this.props.course.data.is_trial;
  }

  isTrialChang() {
    return this.props.course.data.course_id === "632bec62293ed67329e02c62";
  }

  isNbFree() {
    return this.props.course.data.course_id === "642540848a9def2d6766b27a";
  }

  isTrialHr() {
    return this.props.course.data.course_id === "632ad50b2b63868e4030eb5b";
  }

  isTrialYouth() {
    return this.props.course.data.course_id === "6334259bd6566f2cb23ec7f7";
  }

  isTrialGpt() {
    return this.props.course.data.course_id === "6420ea5f7e6e4c8f5d0af5d0";
  }

  isB2BKoreaInvest() {
    return this.props.round.data.round.includes("한국투자");
  }

  isB2BGS() {
    return this.props.round.data.round.includes("GS");
  }

  isMajorInputRequired() {
    const { isGovCredit } = this.state;
    if (
      !this.isGovIntro() &&
      !isGovCredit &&
      (!this.props.user.major || !this.props.user.job)
    ) {
      return true;
    }
  }

  isMajorAnswered = () => {
    const { major } = this.state;
    return !(major === undefined || major === "etc");
  };

  isJobInputRequired() {
    return this.isMajorInputRequired();
  }

  isJobAnswered = () => {
    const { job } = this.state;
    return !(job === undefined);
  };

  isRefInputRequired() {
    return !this.isB2BKoreaInvest();
  }

  _getStartMaterial = async (course_id) => {
    let endpoint = `${config.apiUrl}/v1/materials/start?course_id=${course_id}&prequestion=y`;
    let options = getGETAuthOptions();
    await fetch(endpoint, options).then(
      (resp) => {
        return resp.json();
      },
      (error) => {}
    );
  };

  chtalkPurchaseLog = (user, data) => {
    const { ChannelIO } = window;
    ChannelIO("boot", {
      pluginKey: "74fefd0d-c880-4ec7-b06f-d09eeba19b43",
      memberId: user?._id,
      profile: {
        name: user?.name,
        mobileNumber: user?.phone,
        email: user?.email,
      },
    });
    ChannelIO("track", "Purchase", data);
  };

  invalidAnswers = () => {
    alert(`필수 항목을 모두 작성해 주세요!`);
    this.setState({ isLoading: false });
  };

  isRefRequired = () => {
    const { isGovCredit } = this.state;
    return !isGovCredit && !this.isB2BKoreaInvest() && !this.isB2BGS();
  };

  isRefAnswered = () => {
    const { reference, courseSelected } = this.state;
    if (reference === "코멘토") {
      if (this.isGovIntro() && courseSelected.length < 1) {
        return false;
      }
    }

    return !(reference === undefined || reference === "etc");
  };

  isGoalRequired = () =>
    !this.state.is_event &&
    this.isTrial() &&
    !this.isTrialChang() &&
    !this.isTrialHr() &&
    !this.isNbFree() &&
    !this.isYouthGoalRequired() &&
    !devCareerPrepCoursesList.includes(this.state.course_id);

  isNbIntroRequired = () =>
    !this.state.is_event &&
    this.isTrial() &&
    !this.isTrialChang() &&
    !this.isTrialHr() &&
    !this.isNbFree() &&
    !this.isYouthGoalRequired() &&
    !devCareerPrepCoursesList.includes(this.state.course_id);

  isChangGoalRequired = () => !this.state.is_event && this.isTrialChang();
  isNBFreeGoalRequired = () => !this.state.is_event && this.isNbFree();
  isHrGoalRequired = () => !this.state.is_event && this.isTrialHr();

  isYouthGoalRequired = () => !this.state.is_event && this.isTrialYouth();
  isGptGoalRequired = () => this.isTrialGpt();
  isGoalAnswered = () => {
    const { goal } = this.state;
    return !(goal === undefined);
  };

  isNbIntroAnswered = () => {
    const { nbIntro } = this.state;
    return !(nbIntro === undefined);
  };

  isAchieveRequired = () =>
    !this.isGoalRequired() &&
    !this.isGovIntro() &&
    !this.isChangGoalRequired() &&
    !this.isNBFreeGoalRequired() &&
    !this.isHrGoalRequired() &&
    !this.isYouthGoalRequired() &&
    !devCareerPrepCoursesList.includes(this.state.course_id);

  isMotivationRequired = () =>
    !this.isGoalRequired() &&
    !this.isGovIntro() &&
    !this.isChangGoalRequired() &&
    !this.isNBFreeGoalRequired() &&
    !this.isHrGoalRequired() &&
    !this.isYouthGoalRequired() &&
    !devCareerPrepCoursesList.includes(this.state.course_id);

  isAchieveAnswered = () => {
    const { achievement } = this.state;
    return !(achievement === undefined && /^[\x00-\x7F]*$/.test(achievement));
  };

  isMotivationAnswered = () => {
    const { motivation } = this.state;
    return !(motivation === undefined && /^[\x00-\x7F]*$/.test(motivation));
  };

  isDecideRequired = () => this.state.isTrial && !this.isTrialGpt();

  isDecideAnswered = () => !(this.state.decide === undefined);

  isReadyRequired = () => this.state.isPirates;

  isReadyAnswered = () => !(this.state.ready === undefined);

  onChange = (value) => {
    const holders = {
      "인스타그램/페이스북": "",
      코멘토: "",
      "구글검색/유튜브": "",
      무료특강: "",
      "네이버검색/블로그": "",
      카카오톡배너: "",
      옥외광고:
        "어떤 지역에서 보셨나요? (ex. 강남 / 판교 / 서울대 / 고속터미널 / 그 외)",
      지인추천: "친구가 뭐라고 하면서 추천해주었는지 궁금해요!",
      재구매: "스파르타의 어떤 점이 좋으셨나요?",
      기타: "스파르타를 아시게 된 경로가 궁금해요! (예. 뉴스기사, 로켓펀치 등)",
    };
    this.setState({
      reference: value,
      refDetailRequired: holders[value] !== "",
      refDetailPlaceholder: holders[value],
    });
  };

  onMajorChange = (value) => {
    this.setState({
      major: value,
    });
  };

  onCourseChange = (values) => {
    this.setState({
      courseSelected: values,
    });
  };

  onJobChange = (value) => {
    this.setState({
      job: value,
    });
  };

  onJobDetailChange = (value) => {
    this.setState({
      jobDetail: value,
    });
  };

  onReferenceDetailChange = (value) => {
    this.setState({
      referenceDetail: value,
    });
  };

  onAchievementChange = (value) => {
    this.setState({
      achievement: value,
    });
  };

  onMotivationChange = (value) => {
    this.setState({
      motivation: value,
    });
  };

  onGoalChange = (value) => {
    this.setState({
      goal: value,
    });
  };

  onNbIntroChange = (value) => {
    this.setState({
      nbIntro: value,
    });
  };

  onDecideChange = (value) => {
    this.setState({
      decide: value,
    });
  };

  onReadyChange = (value) => {
    this.setState({
      ready: value,
    });
  };

  handleRcmdVisible = (isVisible) => {
    this.setState({
      rcmdVisible: isVisible,
    });
  };

  questionFactory = (name, isRequired) => {
    return {
      isRequired: isRequired,
      isAnswered: this.isAnswered.bind(this, name), // TODO: isAnswered 모두 이런 형태로 수정하기
      props: {
        name: name,
        validate: this.isValid.bind(this, name),
        useAnswer: this.useAnswer.bind(this, name),
      },
    };
  };

  render() {
    const { user, course, round } = this.props;
    const { enrolledId, roundId, courseId } = this.state;
    if (
      user === undefined ||
      course === undefined ||
      course.loaded === false ||
      user.phone === undefined ||
      user.email === undefined ||
      user.name === undefined ||
      !round.loaded ||
      !this.props.enrolledDetails.loaded
    ) {
      return <Skeleton />;
    }
    const { rcmdVisible } = this.state;
    const hasCard = this.state.answerMap?.gfs
      ? this.state.answerMap.gfs.answer === "has_card"
      : this.props.orders.data?.context?.others?.gov_initial_status ===
        "has_card";

    if (
      this.isGovIntro() &&
      enrolledId !== undefined &&
      roundId !== undefined
    ) {
      const next = () => {
        if (hasCard) {
          return `${process.env.REACT_APP_SCC_URL}/nb/guide/hrdRegFin`;
        } else {
          return `${process.env.REACT_APP_SCC_URL}/nb/guide`;
        }
      };

      return setTimeout(() => {
        window.location.replace(next(), "_blank");
      }, 500);
    }
    const { course_id } = this.state;
    const { hasWeekZero, isPackage, isForever, period } = this.state;
    const is_event =
      this.props.round.data.is_event ||
      this.props.course.data.keyword === "free_pass" ||
      this.props.course.data.keyword === "deokdam";
    if (
      hasWeekZero ||
      (this.props.course.loaded && this.props.course.data.is_maker) ||
      (this.props.course.loaded &&
        this.props.course.data.keyword === "free_pass")
    ) {
      const nextUrl = "/rcmds";
      sendLog(
        "scc_paymentPrequestion_rcmdFriend",
        {
          button_text: "제출하고 강의실 입장하기",
        },
        "",
        true
      );
      setTimeout(() => {
        history.push({
          pathname: nextUrl,
          state: {
            enrolled_id: this.state.enrolledId,
            round_id: this.state.round_id,
            period: period,
            storyPossible: !(isPackage || isForever || is_event),
            payment: "done",
            course_id: course_id,
            isB2BKoreaInvest: this.isB2BKoreaInvest(),
          },
        });
      }, 500);
    } else {
      const nextUrl = this.isGovIntro() ? "/classroom" : "/rcmds";
      const { enrolledId } = this.state;
      /** 무료강의의 경우 사전설문 제출완료시 바로 1강으로 도착해야한다.*/
      if (this.props.course.data.is_trial) {
        /**TODO: 아래 로직을 전체강의로 적용하기전에 순서대로 정렬되어있는지 확인필요.
         * 무료강의에만 적용하는데에는 무리가 없음.*/
        const enrolledDetailId =
          this.props.enrolledDetails.loaded &&
          this.props.enrolledDetails.data?.weeks[0].enrolled_details[0]
            .enrolled_detail_id;
        setTimeout(() => {
          history.push(
            `/enrolleds/${enrolledId}/edetails/${enrolledDetailId}?course_id=${course_id}`
          );
        }, 500);
      } else if (this.props.course.data?.is_temp_package) {
        setTimeout(() => {
          history.push(`/mypage/myvoucher`);
        }, 500);
      } else {
        setTimeout(() => {
          history.push({
            pathname: nextUrl,
            state: {
              payment: "done",
            },
          });
        }, 500);
      }
    }

    if (this.state.isGovCredit) {
      return (
        <div>
          <NavContainer />
          <FooterV2 />
        </div>
      );
    }

    return (
      <div>
        <NavContainer />
        <FooterV2 />
        <RcmdModal
          visible={rcmdVisible}
          handleVisible={this.handleRcmdVisible}
          course_loaded={course}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    authentication,
    isTestPaid,
    course,
    prequestionSubmitted,
    isPrequestionExist,
    activeEnrolleds,
    round,
    orders,
    govCourses,
    enrolledDetails,
  } = state;
  const { user } = authentication;
  return {
    authentication,
    user,
    isTestPaid,
    course,
    prequestionSubmitted,
    isPrequestionExist,
    activeEnrolleds,
    round,
    orders,
    govCourses,
    enrolledDetails,
  };
}

const connectedPaymentPrequestionPage = connect(mapStateToProps, {
  getEnrolledDetails: enrolledActions.getEnrolledDetails,
  getCourse: courseActions.getCourse,
  getGovCourse: courseActions.getGovCourses,
  login: userActions.login,
  loginWithToken: userActions.loginWithToken,
  isPreqSubmitted: prequestionActions.isPreqSubmitted,
  getPrequestionExist: prequestionAction.getPrequestionExist,
  getActiveEnrolleds: enrolledsAction.getActiveEnrolleds,
  setRound: roundsActions.setRound,
  getOrder: ordersActions.getOrder,
})(PaymentPrequestionPage);
export { connectedPaymentPrequestionPage as PaymentPrequestionPage };
