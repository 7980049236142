import { useQuery } from "react-query";
import privateInstanceV3 from "../../_helper/axiosV3";
import { sendLog } from "../../businesslogics/logging";
import { isReceiptDownloadable } from "../../components/organisms/Mypage/Certificates/Certificates";
import { useUser } from "../../pages/LecturePageV3/useInitialize";

export const useGetValidEnrollments = () => {
  const user = useUser();
  const userId = user?._id;
  return useQuery(["validEnrollments", userId], () => fetchValidEnrollments(), {
    enabled: !!userId, // userId가 존재할 때만 쿼리 실행
  });
};

export const useGetReceiptInfo = (enrollmentId, systemType) => {
  return useQuery(
    ["receiptInfo", enrollmentId, systemType],
    () => fetchReceiptInfo(enrollmentId, systemType),
    {
      enabled: !!enrollmentId && !!systemType,
    }
  );
};

export const fetchValidEnrollments = async () => {
  const response = await privateInstanceV3.get(
    `/enrollment-record/enrollment/not-canceled`
  );
  return response.data;
};

export const fetchReceiptInfo = async (enrollmentId, systemType) => {
  const response = await privateInstanceV3.get(
    `/enrollment-record/enrollment/${enrollmentId}/receipt-info?systemType=${systemType}`
  );
  return response.data;
};

export const useReceipt = (enrollment) => {
  const { data: receiptInfo } = useGetReceiptInfo(
    enrollment?.enrollmentId,
    enrollment?.systemType
  );

  const handleReceiptClick = () => {
    if (!isReceiptDownloadable(receiptInfo)) return;
    // 필요시 sendLog

    if (receiptInfo.receiptUrl) {
      window.open(receiptInfo.receiptUrl);
    } else if (receiptInfo.tid) {
      window.open(
        `https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${receiptInfo.tid}&type=0`,
        "_blank"
      );
    }
  };

  return { receiptInfo, handleReceiptClick };
};

export const sendLogCertificate = (certificateType, systemType) => {
  sendLog(
    "scc_mypagePage_click_certificate",
    {
      certificate_type: certificateType,
      system_type: systemType,
    },
    "",
    true
  );
};

export const useGetCouponInfo = () => {
  const user = useUser();
  const userId = user?._id;
  return useQuery(["couponInfo", userId], () => fetchCouponInfo(userId), {
    enabled: !!userId,
  });
};

const fetchCouponInfo = async () => {
  const response = await privateInstanceV3.get(`/user-info/available-coupons`);
  return response.data;
};
