import { useUserV2CouponCountQuery } from "../../queries/gnb";

export const useCouponCountText = (userId) => {
  const userCouponCount = useUserV2CouponCountQuery(userId);
  return getCouponCountText(userCouponCount.data);
};

const getCouponCountText = (count) => {
  if (count === undefined) {
    return "";
  }

  if (count > 0) {
    return `${count}장`;
  }
  return "없음";
};
