import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { history } from "../../../../_helpers";
import { numberWithCommas } from "../../../_helper";
import {
  isMouseOverMyPageIconAtom,
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../stores/mobileMyPageTabOpen";
import { sendLog } from "../../logging";
import { useUser } from "../../../pages/LecturePageV3/useInitialize";
import { useLocation } from "react-router-dom";

export const hasRightArrow = (anchor) => anchor.key !== "logout";

export const goLogin = () => {
  const next = encodeURIComponent(window.location.href);
  window.location.href = `${process.env.ONLINE_URL}/login?next=${next}`;
};

export const useMovePage = () => {
  const [, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  useEffect(() => {
    setIsNavigatorOpen(false);
    setIsMyPageOpen(false);
  }, [window.location.pathname]);
};

export const useIsMain = () => {
  const [isMain, setIsMain] = useState(false);
  useEffect(() => {
    setIsMain(window.location.pathname === "/");
  }, [window.location.pathname]);
  return isMain;
};

export const useIsTargetPage = (pathname) => {
  const [isMatch, setIsMatch] = useState(false);
  useEffect(() => {
    setIsMatch(window.location.pathname.split(/(?=\/)/g)[0] === pathname);
  }, [window.location.pathname]);
  return isMatch;
};

export const useGoMain = () => {
  return () => {
    window.location.href = process.env.REACT_APP_SCC_MAIN_URL;
  };
};

export const useRouterTitle = () => {
  const [title, setTitle] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/classroom")) {
      setTitle("내 강의실");
    } else if (location.pathname.includes("/roadmap")) {
      setTitle("강의 수강");
    } else if (location.pathname.includes("/myvoucher")) {
      setTitle("수강권");
    } else if (location.pathname.includes("/mycertificate")) {
      setTitle("수강증/수료증");
    } else if (location.pathname.includes("/mybasicinfo")) {
      setTitle("계정");
    } else if (location.pathname.includes("/mycoupon")) {
      setTitle("쿠폰");
    } else if (location.pathname.includes("/mypoint")) {
      setTitle("포인트");
    } else if (location.pathname.includes("/feedback")) {
      setTitle("숙제 피드백");
    }
  }, [location.pathname]);
  return title;
};

export const useUserMeta = (userMeta) => {
  const [userMetaData, setUserMetaData] = useState({
    classroom: "",
    point: "",
    coupon: "",
    voucher: "",
  });
  useEffect(() => {
    if (Object.keys(userMeta).length) {
      let tempObject = {
        classroom: userMeta.is_enrolled_exist ? "수강중" : "",
        point: `${numberWithCommas(userMeta.point_amount)}원`,
        coupon: `${userMeta.active_coupon_count}장`,
        voucher: `${userMeta.active_voucher_count}장`,
      };
      setUserMetaData(tempObject);
    }
  }, [userMeta]);
  return userMetaData;
};

export const useClickAnchor = (anchor) => {
  const [, setIsMouseOverMyPageIcon] = useAtom(isMouseOverMyPageIconAtom);
  const user = useUser();

  return () => {
    if (anchor.href) {
      const eventName = getLogEventName(anchor.key);
      // outlink 인 경우에만 새창에서 띄우고, 같은 도메인인 경우 router 로 이동
      sendLog(eventName, {
        band: anchor?.brand ?? "",
        page_url: window.location.href,
        page_title: document.title,
        button_text: anchor.name,
        button_href: anchor.href,
      });
      if (getOutLinkTarget(anchor.key)) {
        window.open(anchor.href);
      } else if (anchor.href.includes("://")) {
        window.location.href = anchor.href;
      } else if (anchor.name.includes("숙제 피드백")) {
        window.location.href = anchor.href + `/${user._id}`;
      } else {
        history.push(anchor.href);
      }
      setIsMouseOverMyPageIcon(false);
    } else if (!!anchor.onClick) {
      anchor.onClick();
    }
  };
};

export const useOnOutsideClick = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
};

export const getLogEventName = (keyName) => {
  const gnbAnchorKeys = [
    "scc",
    "catalog",
    "nb",
    "chang",
    "hanghae",
    "blog",
    "community",
    "swcamp",
    "intellipick",
    "ddingdong",
  ];
  const mypageAnchorKeys = [
    "classroom",
    "point",
    "coupon",
    "voucher",
    "certificate",
    "faq",
    "notice",
    "basicInfo",
    "logout",
    "rcmdsfree",
    "registrationStatus",
  ];
  if (gnbAnchorKeys.indexOf(keyName) !== -1) return "scc_gnb_click";
  if (mypageAnchorKeys.indexOf(keyName) !== -1) return "scc_myPage_click";
  if (keyName === "b2b") return "scc_b2b_click";
  if (["bizRecruit", "bizOutsourcing", "bizEducation"].indexOf(keyName) !== -1)
    return "scc_b2b_click";
  return "scc_gnb_click";
};

export const getOutLinkTarget = (keyName) => {
  const newTabLinks = [
    "chang",
    "ddingdong",
    "bizRecruit",
    "bizOutsourcing",
    "kdt",
    "contact",
  ];
  if (newTabLinks.indexOf(keyName) !== -1) return true;
  return false;
};
