import { IN_HOUSE_URL } from "../../../../../_consts";
import { numberWithCommas } from "../../../../_helper";
import { ArrowRightSmall } from "../../../organisms/Mypage/Icons/Icons";
import * as S from "./Coupon.style";

const arrayToString = (array) => {
  return array.join(", ");
};

const formatDateTime = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

export const Coupon = ({ couponData }) => {
  return (
    <S.CouponWrapper>
      <S.Coupon>
        <S.CouponName>{couponData.name}</S.CouponName>
        <S.CouponBenefit>
          {numberWithCommas(couponData.benefitValue)}
          {couponData.benefitType === "PERCENTAGE" ? "% 할인" : ""}
          {couponData.benefitType === "AMOUNT" ? "원 할인" : ""}
          {couponData.benefitType === "FIXED_PRICE" ? "원으로 할인" : ""}
        </S.CouponBenefit>
        <S.CouponMinimumCondition>
          -{" "}
          {couponData.minimumProductPrice
            ? `${numberWithCommas(couponData.minimumProductPrice)}` +
              `원 이상 결제시`
            : "구매금액 제한 없음"}
        </S.CouponMinimumCondition>
        <S.CouponExpired>
          - {formatDateTime(couponData.expiresAt)}까지{" "}
          <b>D-{couponData.remainingDays}</b>
        </S.CouponExpired>
        <S.AvailableProductList>
          - 적용 과목: {arrayToString(couponData.availableProducts)}
        </S.AvailableProductList>
      </S.Coupon>
      <S.CouponCtaContainer>
        <S.Cta
          onClick={() => {
            window.location = `${IN_HOUSE_URL.상품탐색}?category=all&sub-category=all`;
          }}
        >
          쿠폰 사용하기 <ArrowRightSmall color={"#141617"} />
        </S.Cta>
      </S.CouponCtaContainer>
    </S.CouponWrapper>
  );
};
