import {
  ArrowDropDownLine,
  ArrowDropUpLine,
  neutralDay,
} from "@teamsparta/design-system";
import { window } from "mixpanel-browser/src/utils";
import React, { useEffect, useState } from "react";
import {
  BusinessInfoItems,
  CopyRightText,
  FooterCustomerService,
  FooterInfoSecondLineItems,
  FooterNavItemV2,
  FooterSNSItems,
  InfoDropDownIconImg,
  InfoDropDownText,
} from "./footer.models";
import * as S from "./FooterV2.style.js";
import { FooterCS, PointerArea } from "./FooterV2.style.js";
import { FooterIconSVG } from "./FooterV2.style.svg.jsx";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const FooterV2 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 820);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 820);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const clickCta = (navIdx, textIdx) => {
    const url = FooterNavItemV2[navIdx].url[textIdx];
    if (url.includes("http") && !url.includes(process.env.REACT_APP_SCC_URL)) {
      window.open(url, "_blank");
    } else {
      window.open(url, "_self");
    }
  };

  const [expandedItems, setExpandedItems] = useState(
    Array(FooterNavItemV2?.length).fill(false)
  );

  const toggleItem = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  return (
    <>
      {/*{isDesktop || !isExcluded ? ( 기존에 있던 코드인에 없어도 되는 건지.. 일단 주석처리해도 문제없음*/}
      <S.Footer>
        <S.FooterContentWrap>
          <S.GridContainer>
            {/*고객센터 영역*/}
            {FooterCustomerService?.map((item, idx) => (
              <S.GridItem key={idx}>
                <S.FooterTitle>{item.title}</S.FooterTitle>
                <S.FooterContentContainer isCs={true} style={{ gap: "0" }}>
                  {item.text?.map((textString, textIdx) => (
                    <React.Fragment key={textIdx}>
                      {textString.split("<br/>")?.map((line, lineIdx) => {
                        const isCs = textString === "고객센터 바로가기";
                        if (isCs) {
                          return (
                            <FooterCS
                              key={lineIdx}
                              onClick={() => window.open(item.url, "_blank")}
                            >
                              <FooterIconSVG item={"cs"} />
                              {line}
                            </FooterCS>
                          );
                        }

                        return (
                          <S.FooterContent
                            style={{ padding: "0 4px", cursor: "auto" }}
                            key={lineIdx}
                          >
                            {line}
                            {lineIdx !==
                              textString.split("<br/>").length - 1 && <br />}
                          </S.FooterContent>
                        );
                      })}
                      {textIdx !== item.text.length - 1 && <S.Spacer10 />}
                    </React.Fragment>
                  ))}
                </S.FooterContentContainer>
              </S.GridItem>
            ))}

            {!isMobile
              ? FooterNavItemV2.map((item, navIdx) => (
                  <S.GridItem key={navIdx}>
                    <S.FooterTitle>{item.title}</S.FooterTitle>
                    <S.FooterContentContainer>
                      {item.text?.map((textString, textIdx) => (
                        <S.FooterContent key={textString}>
                          <PointerArea
                            onClick={() => clickCta(navIdx, textIdx)}
                          >
                            {textString}
                          </PointerArea>
                          {textIdx !== item.text.length - 1 && <S.Spacer6 />}
                          {textString === "인재 채용" && (
                            <S.Tag>{item.tag}</S.Tag>
                          )}
                        </S.FooterContent>
                      ))}
                    </S.FooterContentContainer>
                  </S.GridItem>
                ))
              : FooterNavItemV2.map((item, navIdx) => (
                  <S.GridItem
                    key={navIdx}
                    isOpen={expandedItems[navIdx]}
                    isLastItem={navIdx === FooterNavItemV2.length - 1}
                  >
                    <S.FooterTitle onClick={() => toggleItem(navIdx)}>
                      {item.title}
                      {expandedItems[navIdx] ? (
                        <ArrowDropUpLine
                          size={16}
                          color={`${neutralDay.gray50}`}
                        />
                      ) : (
                        <ArrowDropDownLine
                          size={16}
                          color={`${neutralDay.gray50}`}
                        />
                      )}
                    </S.FooterTitle>

                    {expandedItems[navIdx] && (
                      <S.FooterContentContainer>
                        {item.text?.map((textString, textIdx) => (
                          <S.FooterContent
                            key={textString}
                            onClick={() => clickCta(navIdx, textIdx)}
                          >
                            {textString}
                            {textIdx !== item.text.length - 1 && <S.Spacer6 />}
                            {textString === "인재 채용" && (
                              <S.Tag>{item.tag}</S.Tag>
                            )}
                          </S.FooterContent>
                        ))}
                      </S.FooterContentContainer>
                    )}
                  </S.GridItem>
                ))}
          </S.GridContainer>

          <S.FooterLine />
          <PrivacyPolicy
            footerSNSItems={FooterSNSItems}
            footerInfoSecondLineItems={FooterInfoSecondLineItems}
            businessInfoItems={BusinessInfoItems}
            infoDropDownText={InfoDropDownText}
            infoDropDownIconImg={InfoDropDownIconImg}
            copyRightText={CopyRightText}
          />
        </S.FooterContentWrap>
      </S.Footer>
    </>
  );
};
